@import "se-colors";

.se-button {
  border-radius: 20px;
  height: 40px;
  text-wrap: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  box-shadow: none;
  margin: 0 2px;

  &--primary {
    background-color: $purple-350;
    border-color: $purple-350;

    span {
      color: $white;
    }

    &:hover,
    &:active {
      background-color: $purple-500 !important;
      border-color: $purple-500 !important;
    }
  }

  &--secondary {
    background-color: $white;
    border-color: $purple-350;

    span {
      color: $purple-350;
    }

    &:hover,
    &:active {
      span {
        color: $purple-500;
      }
      border-color: $purple-500 !important;
    }
  }

  span {
    font-size: 16px;
    font-family: "Siemens Sans Roman";
    padding: 0 20px;
  }

  &.btn:disabled {
    span {
      color: $neutral-70;
    }
  }
}
