$dark-theme: false;

@import "bootstrap/scss/bootstrap";

@import "primeng/resources/themes/lara-light-purple/theme.css";
@import "primeng/resources/primeng.css";

@import "./styles/se-shared/se-styles.scss";

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Siemens Sans Roman";
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
}

.p-dialog {
  max-width: 1000px;
}

.max-w-full {
  max-width: 100%;
}
